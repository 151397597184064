<template>
  <b-alert
    variant="primary"
    show
  >
    <div class="alert-body">
      Online sistem yakında eklecenektir...
    </div>
  </b-alert>
</template>

<script>
export default {}
</script>

<style></style>
